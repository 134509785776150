import React from 'react'

export const Skills = () => {
    return (
        <section className="section3 header" id="skills">
            <div className="skills-container header">
                <div className="header">
                    <i className="fa-brands fa-html5"></i>
                    <span>HTML</span>
                </div>
                <div className="header">
                    <i className="fa-brands fa-css3"></i>
                    <span>CSS</span>
                </div>
                <div className="header">
                    <i className="fa-brands fa-square-js"></i>
                    <span>JavaScript</span>
                </div>
                <div className="header">
                    <i className="fa-brands fa-react"></i>
                    <span>React</span>
                </div>
                <div className="header">
                    <i className="fa-brands fa-sass"></i>
                    <span>Sass</span>
                </div>
            </div>

        </section >
    )
}
